import type { FormHeadingTranslationObj } from "../../translation-keys/form-heading-text-keys.js";

const GenericHeading = "このフォームに記入して、リソースをダウンロードしてください。";

const FormHeading: FormHeadingTranslationObj={
"document-Heading": GenericHeading,
"document-landing-page-Heading": GenericHeading,
"post-event-assets-Heading": "",
"ppc-Heading": GenericHeading,
"case-study-Heading": GenericHeading,
"video-Heading": GenericHeading,
"video-landing-page-Heading": GenericHeading,
"video-gated-Heading": GenericHeading,
"events-registration-Heading": "下記のフォームに記入して登録してください。",
"resource-forms-Heading": "このフォームに記入して、サポートリソースをダウンロードしてください。",
"contact-Heading": "製品やサービスに関するお問い合わせは、こちらのフォームからご連絡ください",
"qx-roi-Heading": "",
"mx-roi-Heading": ""
};

export default FormHeading;